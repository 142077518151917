import React from "react"
import Layout from "../components/layout/layout"
import LandingHero from "../components/landing/landingHero"
import LandingContent from "../components/landing/landingContent"
import LandingMid from "../components/landing/landingMid"
import Header from "../components/header"
import MobileNav from "../components/mobileNav"
import SEO from "../components/seo"

const Data = () => {
  return (
    <>
      <SEO title="Home" />
      <Header />
      <MobileNav />
      <Layout>
        <LandingHero />
        <LandingMid />
        <LandingContent />
      </Layout>
    </>
  )
}

export default Data
