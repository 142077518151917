import React from "react"
import Box from "@material-ui/core/Box"

const LandingContent = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-evenly"
      py={{ xs: 3, sm: 4, md: 5 }}
      width={4 / 5}
      mx="auto"
    >
      <Box
        fontSize="h4.fontSize"
        fontWeight={500}
        letterSpacing={1.5}
        lineHeight={1.5}
        width={1}
        textAlign="center"
      >
        Ready for insights about your business that are data-driven and
        meaningful?
      </Box>
      <Box
        fontSize="h5.fontSize"
        width={{ xs: 1, sm: 1, md: 4 / 10 }}
        py={3}
        letterSpacing={1.5}
        lineHeight={1.5}
      >
        Give us 20 minutes and we will tell you how we can partner and unlock
        the full potential of your data with custom dashboards.
      </Box>
      <Box
        fontSize="h5.fontSize"
        width={{ xs: 1, sm: 1, md: 4 / 10 }}
        py={3}
        letterSpacing={1.5}
        lineHeight={1.5}
      >
        <strong> TOGETHER, WE WILL ACCOMPLISH 3 THINGS ON OUR CALL:</strong>
        <br />
        <br />
        1. Quick audit of your current data challenges and reporting practices.
        <br />
        2. Share recommendations for getting more out of your data.
        <br />
        3. Provide a game plan for how we can work together.
      </Box>
    </Box>
  )
}

export default LandingContent
