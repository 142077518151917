import React from "react"
import Box from "@material-ui/core/Box"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "data_idea.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Box width={{ xs: 1, sm: 1, md: 1 / 2, lg: 6 / 10 }} my={4}>
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Data has a better idea"
      />
    </Box>
  )
}
