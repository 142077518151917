import React from "react"
import Box from "@material-ui/core/Box"

const LandingHero = () => {
  return (
    <Box
      display="flex"
      flexDirection="wrap"
      flexWrap="wrap"
      width={4 / 5}
      mx="auto"
    >
      <Box
        fontSize="h3.fontSize"
        fontWeight={500}
        lineHeight={1.5}
        letterSpacing={1.5}
        pt={6}
        pb={3}
        textAlign="center"
        order={1}
      >
        GIVE US 20 MINUTES, WE’LL GIVE YOU A DASHBOARD GAME PLAN, & PROPOSAL
        TO ACT ON.
      </Box>
    </Box>
  )
}

export default LandingHero
