import React from "react"
import Box from "@material-ui/core/Box"
import LandingImage from "./landingImage"
import LandingForm from "./landingForm"

const LandingMid = () => {
  return (
    <Box
      display="flex"
      flexDirection="wrap"
      flexWrap="wrap"
      width={4 / 5}
      justifyContent="space-evenly"
      mx="auto"
      my={4}
    >
      <LandingImage />
      <LandingForm />
    </Box>
  )
}

export default LandingMid
